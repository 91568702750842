import { ACTIONS } from '../constants/events';

const INITIAL_STATE = {
  eventsList: { events: [], params: {}, pager: null, loading: false, error: null },
  activeEvent: { event: null, loading: false, error: null },
};

export const events = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.EVENTS_FETCH_START:
      return {
        ...state,
        eventsList: {
          ...state.eventsList,
          loading: true,
          error: null,
          params: action.payload || {}, // Store fetch parameters if provided
        },
      };

    case ACTIONS.EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        eventsList: {
          ...state.eventsList,
          events: action.payload.results,
          pager: action.payload.paginator,
          loading: false,
          error: null,
        },
      };

    case ACTIONS.EVENTS_FETCH_FAILURE:
      return {
        ...state,
        eventsList: {
          ...state.eventsList,
          loading: false,
          error: action.payload, // Capture error details
        },
      };

    case ACTIONS.EVENT_FETCH_START:
      return {
        ...state,
        activeEvent: {
          event: action.payload.event ? action.payload.event : null,
          slots: null,
          loading: true,
          error: null,
        },
      };

    case ACTIONS.EVENT_FETCH_SUCCESS:
      return {
        ...state,
        activeEvent: {
          event: action.payload,
          slots: action.payload.slots,
          loading: false,
          error: null,
        },
      };

    case ACTIONS.EVENT_FETCH_FAILURE:
      return {
        ...state,
        activeEvent: {
          event: null,
          slots: null,
          loading: false,
          error: action.payload,
        },
      };

    case ACTIONS.EVENT_CREATE_START:
    case ACTIONS.EVENT_UPDATE_START:
    case ACTIONS.EVENT_DELETE_START:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          loading: true,
          error: null,
        },
      };

    case ACTIONS.EVENT_CREATE_SUCCESS:
      return {
        ...state,
        eventsList: {
          ...state.eventsList,
          events: [...state.eventsList.events, action.payload], // Add the new event to the list
        },
        activeEvent: {
          event: action.payload,
          loading: false,
          error: null,
        },
      };

    case ACTIONS.EVENT_UPDATE_SUCCESS:
      return {
        ...state,
        eventsList: {
          ...state.eventsList,
          events: state.eventsList.events.map(evt =>
            evt.id === action.payload.id ? action.payload : evt
          ),
        },
        activeEvent: {
          event: action.payload,
          slots: action.payload.slots,
          loading: false,
          error: null,
        },
      };

    case ACTIONS.EVENT_DELETE_SUCCESS:
      return {
        ...state,
        eventsList: {
          ...state.eventsList,
          events: state.eventsList.events.filter(evt => evt.id !== action.payload.id),
        },
        activeEvent: {
          event: null,
          loading: false,
          error: null,
        },
      };

    case ACTIONS.EVENT_CREATE_FAILURE:
    case ACTIONS.EVENT_UPDATE_FAILURE:
    case ACTIONS.EVENT_DELETE_FAILURE:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          loading: false,
          error: action.payload,
        },
      };

    case ACTIONS.EVENT_RESET:
      return {
        ...state,
        activeEvent: { event: null, loading: false, error: null },
      };

    default:
      return state;
  }
};
