import asyncComponent from './helpers/async-func';

const routes = [
  {
    path: '/login',
    name: 'APP.LOGIN',
    component: asyncComponent(() => import('./views/login-view')),
  },
  {
    path: '/forgot-password',
    name: 'APP.FORGOT_PASSWORD',
    component: asyncComponent(() => import('./views/forgot-password-view')),
  },
  {
    path: '/reset-password/:token',
    name: 'APP.RESET_PASSWORD',
    component: asyncComponent(() => import('./views/reset-password-view')),
  },
  {
    path: '/accept-invitation/:token',
    name: 'APP.ACCEPT-INVITATION',
    component: asyncComponent(() => import('./views/accept-invitation-view')),
  },
  {
    path: '/confirm-email/:token/:email',
    name: 'APP.CONFIRM_EMAIL',
    component: asyncComponent(() => import('./views/confirm-email-view')),
  },
  {
    path: '/forbidden',
    name: 'APP.FORBIDDEN',
    component: asyncComponent(() => import('./views/forbidden-view')),
  },
  {
    path: '/profile',
    name: 'APP.PROFILE',
    component: asyncComponent(() => import('./views/profile-view')),
    exact: true,
    permissions: ['Admin', 'Buddy'],
    requiresAuth: true,
  },
  {
    path: '/',
    name: 'APP.HOME',
    sidebar: true,
    icon: 'icon-home',
    component: asyncComponent(() => import('./views/dashboard-view')),
    exact: true,
    permissions: ['Admin', 'Buddy'],
    requiresAuth: true,
  },
  {
    path: '/bike-buddy-scheduler',
    name: 'APP.BIKE-BUDDY-SCHEDULER',
    sidebar: true,
    icon: 'icon-calendar',
    component: asyncComponent(() => import('./views/bike-buddy-scheduler-view')),
    exact: true,
    permissions: ['Admin', 'Buddy'],
    requiresAuth: true,
  },
  {
    path: '/bike-buddy-scheduler/calendar',
    name: 'APP.BIKE-BUDDY-SCHEDULER',
    icon: 'icon-calendar',
    component: asyncComponent(() => import('./views/work-scheduler-view')),
    exact: true,
    permissions: ['Admin', 'Buddy'],
    requiresAuth: true,
  },
  {
    path: '/companies/:id',
    name: 'APP.COMPANY.DETAILS',
    component: asyncComponent(() => import('./views/company-details-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/companies',
    name: 'APP.COMPANIES',
    sidebar: true,
    icon: 'icon-briefcase',
    component: asyncComponent(() => import('./views/companies-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/event-bookings',
    name: 'APP.BOOKINGS.TITLE',
    sidebar: true,
    icon: 'icon-briefcase',
    component: asyncComponent(() => import('./views/event-bookings-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/events',
    name: 'APP.EVENTS',
    sidebar: true,
    icon: 'icon-briefcase',
    component: asyncComponent(() => import('./views/events-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/events/:id',
    name: 'APP.EVENT.DETAILS',
    component: asyncComponent(() => import('./views/event-details-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/promo-codes',
    name: 'APP.PROMO-CODES',
    sidebar: true,
    icon: 'icon-present',
    component: asyncComponent(() => import('./views/promo-codes-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/promo-codes/:id',
    name: 'APP.PROMO-CODES',
    component: asyncComponent(() => import('./views/promo-code-details-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/services',
    name: 'APP.SERVICES',
    sidebar: true,
    icon: 'icon-book-open',
    component: asyncComponent(() => import('./views/services-view')),
    exact: true,
    permissions: ['Admin', 'Buddy'],
    requiresAuth: true,
  },
  {
    path: '/services/calendar',
    name: 'APP.SERVICES',
    sidebar: false,
    icon: 'icon-book-open',
    component: asyncComponent(() => import('./views/services-calendar-view')),
    exact: true,
    permissions: ['Admin', 'Buddy'],
    requiresAuth: true,
  },
  {
    path: '/services/:id',
    component: asyncComponent(() => import('./views/service-details-view')),
    exact: true,
    permissions: ['Admin', 'Buddy'],
    requiresAuth: true,
  },
  {
    path: '/expert',
    name: 'APP.EXPERT',
    sidebar: true,
    icon: 'icon-wrench',
    component: asyncComponent(() => import('./views/expert-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/service-items',
    name: 'APP.SERVICE-ITEMS',
    sidebar: true,
    icon: 'icon-wrench',
    component: asyncComponent(() => import('./views/service-items-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/service-slots',
    name: 'APP.SERVICE-SLOTS',
    sidebar: true,
    icon: 'icon-list',
    component: asyncComponent(() => import('./views/service-slots-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  /*{
    path: '/subscriptions',
    name: 'APP.SUBSCRIPTIONS',
    sidebar: true,
    icon: 'fa fa-credit-card',
    component: asyncComponent(() => import('./views/subscriptions-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },*/
  {
    path: '/languages',
    name: 'APP.LANGUAGES',
    sidebar: true,
    icon: 'icon-flag',
    component: asyncComponent(() => import('./views/languages-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/competencies',
    name: 'APP.COMPETENCIES',
    sidebar: true,
    icon: 'icon-magic-wand',
    component: asyncComponent(() => import('./views/competencies-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/bicycle-types',
    name: 'APP.BICYCLE-TYPES',
    sidebar: true,
    icon: 'fa fa-bicycle',
    component: asyncComponent(() => import('./views/bicycle-types-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/users/:id',
    name: 'APP.USER.DETAILS',
    component: asyncComponent(() => import('./views/user-details-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/users',
    name: 'APP.USERS',
    sidebar: true,
    icon: 'icon-user',
    component: asyncComponent(() => import('./views/users-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/roles',
    name: 'APP.ROLES',
    sidebar: true,
    icon: 'icon-graduation',
    component: asyncComponent(() => import('./views/roles-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/bicycles/:id',
    name: 'APP.BICYCLE.DETAILS',
    component: asyncComponent(() => import('./views/bicycle-details-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/privacy-policy',
    component: asyncComponent(() => import('./views/privacy-policy-view')),
    exact: true,
    requiresAuth: false,
  },
  {
    path: '/terms-and-conditions/:lang',
    component: asyncComponent(() => import('./views/terms-and-conditions-view')),
    exact: true,
    requiresAuth: false,
  },
  {
    path: '/subscriptions/:plan/:language?',
    component: asyncComponent(() => import('./views/subscription-details-view')),
    exact: true,
    requiresAuth: false,
  },
  {
    path: '/operational-ranges',
    name: 'Operational Ranges',
    sidebar: true,
    icon: 'icon-map',
    component: asyncComponent(() => import('./views/operational-ranges-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/trial-groups',
    name: 'APP.TRIAL-GROUPS',
    sidebar: true,
    icon: 'icon-notebook',
    component: asyncComponent(() => import('./views/trial-groups-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/trial-groups/:id',
    name: 'APP.TRIAL-GROUP-DETAILS',
    component: asyncComponent(() => import('./views/trial-group-details-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/bicycles',
    name: 'APP.BICYCLES',
    sidebar: true,
    icon: 'fa fa-bicycle',
    component: asyncComponent(() => import('./views/bicycles-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
  {
    path: '/products',
    name: 'APP.PRODUCTS',
    sidebar: true,
    icon: 'icon-basket-loaded',
    component: asyncComponent(() => import('./views/products-view')),
    exact: true,
    permissions: ['Admin'],
    requiresAuth: true,
  },
];

export default routes;
