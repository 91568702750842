// local configuration object
const local = {
  GOOGLE_CLIENT_ID: '',
  API_BASE_URL: 'http://localhost:8000/',
  // API_BASE_URL: 'https://dev-api.mioo.cc/',
  MIOO_OPERATION_CENTER: { lat: 59.332054, lng: 18.067424 },
  MIOO_OPERATION_RADIUS: 3700,
};

// dev environment
const development = {
  GOOGLE_CLIENT_ID: '',
  API_BASE_URL: 'https://dev-api.mioo.cc/',
  MIOO_OPERATION_CENTER: { lat: 59.332054, lng: 18.067424 },
  MIOO_OPERATION_RADIUS: 3700,
  BOOKING_SERVICE_URL: 'https://dev-cykelverkstad.mioo.cc/',
};

// staging environment
const staging = {
  GOOGLE_CLIENT_ID: '',
  API_BASE_URL: 'https://stg-api.mioo.cc/',
  MIOO_OPERATION_CENTER: { lat: 43.855069, lng: 18.399384 },
  MIOO_OPERATION_RADIUS: 3700,
  BOOKING_SERVICE_URL: 'https://stg-cykelverkstad.mioo.cc/',
};

const production = {
  GOOGLE_CLIENT_ID: '',
  API_BASE_URL: 'https://api-v2.mioo.cc/',
  MIOO_OPERATION_CENTER: { lat: 59.332054, lng: 18.067424 },
  MIOO_OPERATION_RADIUS: 3700,
  BOOKING_SERVICE_URL: 'https://cykelverkstad.mioo.cc/',
};

const config =
  process.env.REACT_APP_BUILD_TARGET === 'PRODUCTION'
    ? production
    : process.env.REACT_APP_BUILD_TARGET === 'STAGING'
    ? staging
    : process.env.REACT_APP_BUILD_TARGET === 'DEVELOPMENT'
    ? development
    : local;

export default {
  GOOGLE_MAPS_API_KEY: 'AIzaSyDM_9emSYgyWr0tu2Nkdy60raRVlgmpUhw',
  GOOGLE_MAPS_GEOCODING_API_KEY: 'AIzaSyBZyNG9b514KHz5hF0WYRtjgE69cAoAhmE',
  defaultLanguage: 'en',
  languages: [
    { code: 'en', title: 'APP.LANG.ENGLISH', flag: 'United-Kingdom', name: 'English' },
    { code: 'sv', title: 'APP.LANG.SWEDISH', flag: 'Sweden', name: 'Sweedish' },
  ],
  countries: [
    { code: 'gb', title: 'APP.COUNTRY.UK', name: 'United Kingdom' },
    { code: 'se', title: 'APP.COUNTRY.SWE', name: 'Sweden' },
  ],
  userTypes: [
    { name: 'User', id: 1 },
    { name: 'Buddy', id: 2 },
    //{ name: 'Trial', id: 3 },
    { name: 'Direct Active ', id: 4 },
    { name: 'Bikester ', id: 5 },
  ],
  whitelistedRoles: ['Admin', 'Buddy'],
  serviceStatuses: [
    { id: 1, name: 'Requested', bgColor: '#a3e23d' },
    { id: 2, name: 'Booked', bgColor: '#07737d' },
    { id: 3, name: 'Canceled by Bike Buddy', bgColor: '#ff1414' },
    { id: 4, name: 'Finished', bgColor: '#2dabe9' },
    { id: 5, name: 'Canceled by User or Admin', bgColor: '#f1a306' },
  ],
  currencies: [
    { id: 1, country: 'Sweden', code: 'sek', symbol: 'kr' },
    { id: 2, name: 'United Kingdom', code: 'gbp', symbol: '£' },
  ],
  platform: 'backoffice',
  serviceOriginType: [
    { name: 'one off', value: 'one_off' },
    { name: 'subscriber', value: 'subscriber_' },
    { name: 'e-commerce', value: 'e_commerce' },
    { name: 'b2b', value: 'b2b' },
    { name: 'benefit-bike', value: 'benefit_bike' },
  ],
  ...config,
};
