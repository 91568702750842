export const ACTIONS = {
  EVENT_CREATE_START: 'EVENT_CREATE_START',
  EVENT_CREATE_SUCCESS: 'EVENT_CREATE_SUCCESS',
  EVENT_CREATE_FAILURE: 'EVENT_CREATE_FAILURE',
  EVENT_CREATE_RESET: 'EVENT_CREATE_RESET',

  EVENT_UPDATE_START: 'EVENT_UPDATE_START',
  EVENT_UPDATE_SUCCESS: 'EVENT_UPDATE_SUCCESS',
  EVENT_UPDATE_FAILURE: 'EVENT_UPDATE_FAILURE',
  EVENT_UPDATE_RESET: 'EVENT_UPDATE_RESET',

  EVENT_DELETE_START: 'EVENT_DELETE_START',
  EVENT_DELETE_SUCCESS: 'EVENT_DELETE_SUCCESS',
  EVENT_DELETE_FAILURE: 'EVENT_DELETE_FAILURE',
  EVENT_DELETE_RESET: 'EVENT_DELETE_RESET',

  EVENT_FETCH_START: 'EVENT_FETCH_START',
  EVENT_FETCH_SUCCESS: 'EVENT_FETCH_SUCCESS',
  EVENT_FETCH_FAILURE: 'EVENT_FETCH_FAILURE',
  EVENT_FETCH_RESET: 'EVENT_FETCH_RESET',

  EVENTS_FETCH_START: 'EVENTS_FETCH_START',
  EVENTS_FETCH_SUCCESS: 'EVENTS_FETCH_SUCCESS',
  EVENTS_FETCH_FAILURE: 'EVENTS_FETCH_FAILURE',
  EVENTS_FETCH_RESET: 'EVENTS_FETCH_RESET',

  EVENT_RESET: 'EVENT_RESET',
};
