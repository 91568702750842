import { ACTIONS } from '../constants/statistics';

const initialState = {
  data: [],
  paginator: {},
  loading: false,
  error: null,
};

export function statistics(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.STATISTICS_FETCH_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTIONS.STATISTICS_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.results,
        paginator: action.payload.paginator,
        error: null,
      };

    case ACTIONS.STATISTICS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ACTIONS.STATISTICS_FETCH_RESET:
      return initialState;
    default:
      return state;
  }
}
